import React from "react";

export default class AddOption extends React.Component {
  state = {
    error: undefined
  };
  handleAddOption = e => {
    e.preventDefault();
    const option = e.target.elements.option.value.trim();
    const error = this.props.handleAddOption(option);

    this.setState(() => ({ error }));

    if (!error) {
      e.target.elements.option.value = "";
    }
  };
  render() {
    return (
      <div>
        {this.state.error && (
          <p className="add-option-error">{this.state.error}</p>
        )}
        <form className="add-option" onSubmit={this.handleAddOption}>
          <label htmlFor="option">
            <input
              id="option"
              autoFocus
              className="add-option__input"
              type="text"
              name="option"
              placeholder="Learn React"
              aria-label="Option Input"
              aria-required="true"
            />
          </label>
          <button className="button">Add Option</button>
        </form>
      </div>
    );
  }
}
